import { render, staticRenderFns } from "./OrderTags.vue?vue&type=template&id=647cf9e2&scoped=true"
import script from "./OrderTags.vue?vue&type=script&lang=js"
export * from "./OrderTags.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647cf9e2",
  null
  
)

export default component.exports